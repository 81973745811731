'use strict';

/**
 * @ngdoc component
 * @name uasApp.component:participateSidebar
 * @description Shows an interactive sidebar with a progress indicator and tooltip per page
 *
 * A page must have attributes 'label' and 'block' with 'editableQuestions'. It can be 'required', 'answered' and have an 'icon'.
 */
angular.module('diceApp')
  .component('participateSidebar', {
    bindings: {
      pages: '<',
      activePageIndex: '@',
      onPageClick: '&'
    },
    templateUrl: 'es6/participate/participate.sidebar.html',
    controllerAs: 'participateSidebarController',
    controller: function (translateFilter) {
      const participateSidebarController = this;

      const classes = {
        ALL_DONE: { text: 'text-success', icon: 'fa-check' },
        REQUIRED_DONE: { text: 'text-warning', icon: 'fa-flip-horizontal fa-adjust' },
        REQUIRED_NOT_DONE: { text: 'text-danger', icon: 'fa-asterisk' }
      };

      participateSidebarController.$onChanges = function() {
        participateSidebarController.pages_ = buildPages(participateSidebarController.pages);
      };

      function buildPages(pages) {
        const pages_ = pages.map((page) => {
          const editableQuestions = _.get(page, 'block.editableQuestions');
          if (_.isEmpty(editableQuestions)) {
            return page;
          }

          const unansweredQuestions = editableQuestions.filter((question) => !question.answered);

          return {
            ...page,
            questionsTotal: editableQuestions.length,
            questionsAnswered: editableQuestions.filter((question) => question.answered).length,
            questionsUnansweredOptional: unansweredQuestions.filter((question) => !question.required).length,
            questionsUnansweredRequired: unansweredQuestions.filter((question) => question.required).length
          };
        });

        _.each(pages_, (page_) => {
          page_.tooltip = getCounterTooltip(page_);
          page_.classes = getClasses(page_);
        });

        return pages_;
      }

      function getCounterTooltip(page) {
        if (page.questionsAnswered < page.questionsTotal) {
          return translateFilter('Page.Participate.Progress.NotDone.Tooltip', {
            amountRequiredLeft: page.questionsUnansweredRequired,
            amountOptionalLeft: page.questionsUnansweredOptional });
        }

        return translateFilter('Page.Participate.Progress.Done.Tooltip');
      }

      function getClasses(page) {
        if (page.questionsAnswered === page.questionsTotal) {
          return classes.ALL_DONE;
        }

        if (page.questionsAnswered < page.questionsTotal && !page.questionsUnansweredRequired) {
          return classes.REQUIRED_DONE;
        }

        if (page.questionsUnansweredRequired) {
          return classes.REQUIRED_NOT_DONE;
        }

        return '';
      }
    }
  });
