'use strict';

/**
 * @ngdoc component
 * @name diceApp.component:bulkChangeModal
 */
angular.module('diceApp')
  .component('bulkChangeModal', {
    bindings: {
        type: '<',
        evaluations: '<',
        onCancel: '&',
        onChange: '&'
    },
    templateUrl: 'es6/evaluations/bulk/bulk.change.modal.html',
    controllerAs: 'bulkChangeModalController',
    controller: function (translateFilter) {
        const bulkChangeModalController = this;

        bulkChangeModalController.$onInit = function() {
            bulkChangeModalController.modes = [{
                name: 'edit',
                label: 'Page.EvaluationList.BulkChange.Edit',
                icon: 'pencil'
            }, {
                name: 'member',
                label: 'Page.EvaluationList.BulkChange.Member',
                icon: 'user-o'
            }, {
                name: 'question',
                label: 'Page.EvaluationList.BulkChange.Question',
                icon: 'question'
            }, {
                name: 'status',
                label: 'Page.EvaluationList.BulkChange.Status',
                icon: 'arrows'
            }, {
                name: 'notification',
                label: 'Page.EvaluationList.BulkChange.Notification',
                icon: 'mail'
            }, {
                name: 'calculate',
                label: 'Page.EvaluationList.BulkChange.Calculate',
                icon: 'calculate'
            }, {
                name: 'archive',
                label: 'Page.EvaluationList.BulkChange.Archive',
                icon: 'archive'
            }];

            _.forEach(bulkChangeModalController.modes, (mode) => {
              mode.displayName = translateFilter(mode.label);
            });

            bulkChangeModalController.setMode('edit');
        };

        bulkChangeModalController.setMode = function(mode) {
            bulkChangeModalController.mode = mode;
        };

        bulkChangeModalController.cancel = function() {
            bulkChangeModalController.onCancel();
        };
    }
});
