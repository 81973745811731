'use strict';

/**
 * @ngdoc factory
 * @name diceApp.factory:NotificationTemplate
 * @description Resource for NotificationTemplate.
 */
angular.module('diceApp')
  .factory('NotificationTemplate', function($q, DiceResource) {
    const resource = DiceResource('/notification-templates', {
      tags: {
        method: 'GET',
        url: '/notification-templates/tags'
      }
    });

    resource.getByEvaluationType = function(evaluationType) {
      const evaluationTypeId = _.get(evaluationType, 'id');
      if (angular.isUndefined(evaluationTypeId)) {
        return $q.resolve([]);
      }

      return resource.query({
        evaluationTypeId: evaluationTypeId
      }).$promise;
    };

    return resource;
  });
