'use strict';

/**
 * @ngdoc function
 * @name diceApp.controller:diceReportHistogram
 * @description
 * diceReportHistogram
 */
angular.module('diceApp')
  .component('diceReportHistogram', {
    bindings: {
      question: '<'
    },
    templateUrl: 'es6/report/report.histogram.html',
    controllerAs: 'reportHistogramController',
    controller: function (ThemedChart) {
      const reportHistogramController = this;

      reportHistogramController.$onInit = function () {
        reportHistogramController.enableTooltips = false;

        const options = getOptions();
        reportHistogramController.labels = _.map(options, 'chartLabel');

        const answers = _.map(options, (option) => option.percentage || 0);
        reportHistogramController.data = [answers];
        reportHistogramController.hasAnswers = _.sumBy(answers) > 0;
        reportHistogramController.datasetOverride = [{
          barPercentage: 0.9,
          categoryPercentage: 1.0
        }];

        setReportOptions(options);
        reportHistogramController.colors = ThemedChart.getBarColors();
      };

      function getOptions() {
        if (!_.isEmpty(reportHistogramController.question.calculableOptions)) {
          return _.forEach(reportHistogramController.question.calculableOptions, (option) => {
            option.chartLabel = _.parseInt(option.value);
          });
        } else {
          reportHistogramController.enableTooltips = true;
          return _.forEach(reportHistogramController.question.nonCalculableOptions, (option) => {
            option.chartLabel = option.label;
            if (_.isEmpty(option.chartLabel)) {
              option.chartLabel = option.value;
            }
          });
        }
      }

      function setReportOptions(options) {
        reportHistogramController.options = {
          defaultFontSize: 6,
          maintainAspectRatio: true,
          responsive: false,
          average: reportHistogramController.question.averageValue,
          standardDeviation: reportHistogramController.question.standardDeviationValue,
          animation: false,
          scales: {
            yAxes: [{
              ticks: {
                min: 0,
                stepSize: 25,
                callback: function (value) {
                  return value + ' %';
                }
              }
            }],
            xAxes: [{
              position: 'bottom',
              ticks: {
                fontColor: _(options).map('thresholdColor').find(),
                stepSize: 1,
                maxRotation: 0,
                callback: function (value, index) {
                  const thresholdColor = _.get(options[index], 'thresholdColor');
                  if (thresholdColor) {
                    value = `*${value}*`;
                  }

                  return wrap(`${value}`, 2);
                }
              },
              gridLines: {
                display: true
              }
            }]
          },
          tooltips: {
            enabled: reportHistogramController.enableTooltips,
            intersect: false,
            mode: 'index',
            callbacks: {
              label: function () {
                // Only show title in tooltip
                return '';
              }
            }
          }
        };
      }

      /**
       * Wrap a string on multiple lines.
       * 
       * @param {*} str the string to wrap
       * @param {*} limit words on line are wrapped if line length is larger than limit
       * @returns array with wrapped strings
       */
      function wrap(str, limit) {
        const wrapped = [];
        let concat = [];

        const words = str.split(' ');
        _.forEach(words, (word) => {
          concat.push(word);
          const joined = concat.join(' ');
          if (joined.length > limit) {
            wrapped.push(joined);
            concat = [];
          }
        });
        if (!_.isEmpty(concat)) {
          wrapped.push(concat.join(' '));
        }

        return wrapped;
      }
    }
  });
