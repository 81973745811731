'use strict';

/**
 * @ngdoc function
 * @name diceApp.controller:evaluationParticipantList
 * @description
 * evaluationParticipantList Shows the evaluations in list.
 */
angular.module('diceApp')
  .component('participantList', {
    bindings: {
      evaluation: '<',
      operations: '<'
    },
    templateUrl: 'es6/participate/participant.list.html',
    controllerAs: 'participantListController',
    controller: function (Participant, Person, Sheet, Pageable, $uibModal, $scope, diceDateFilter, message) {
      const participantListController = this;

      participantListController.$onInit = function () {
        participantListController.editable = angular.isDefined(participantListController.operations.EDIT_PARTICIPANTS);
        participantListController.searchString = '';
        participantListController.pageable = Pageable.of({
          order: 'fullName'
        });

        loadData();
      };

      participantListController.update = function() {
        loadData();
      };

      function loadData() {
        const params = participantListController.pageable.build({
          evaluationId: participantListController.evaluation.id,
          text: participantListController.searchString
        });

        participantListController.loading = true;
        return Participant.search(params).$promise.then(function (result) {
          participantListController.participants = result;
        }).finally(() => {
          participantListController.loading = false;
        });
      }

      participantListController.exportAsExcel = function () {
        addRows(Sheet.create(), 1).then((sheet) => {
          sheet.download('participants-' + participantListController.evaluation.code + '.xlsx');
        });
      };

      function addRows(sheet, page) {
        const params = participantListController.pageable.build({
          evaluationId: participantListController.evaluation.id,
          text: participantListController.searchString,
          page: page,
          size: 50
        });

        return Participant.search(params).$promise.then((participants) => {
          _.map(participants.content, (participant) => {
            const row = mapToRow(participant);
            sheet.push(row);
          });

          if (participants.last === false) {
            return addRows(sheet, page + 1);
          } else {
            return sheet;
          }
        });
      }

      function mapToRow(participant) {
        const tags = _.mapValues(_.groupBy(participant.tags, 'name'), (it) => {
          return _(it).map('value').sort().uniq().join(', ');
        });

        const row = {
          externalId: _.result(participant.person, 'externalId'),
          fullName: participant.person ? participant.person.fullName : participant.fullName,
          email: participant.email,
          code: participant.code,
          startDate: diceDateFilter(participant.startDate)
        };

        return _.extend(row, tags);
      }

      participantListController.toggle = function (participant) {
        Participant.changeDisabled({
          participant: participant.id
        }, {
          disabled: participant.disabled !== true
        }).$promise.then(function (result) {
          participant.disabled = result.disabled;
        });
      };

      participantListController.open = function () {
        participantListController.loading = true;

        Participant.query({
          evaluationId: participantListController.evaluation.id
        }).$promise.then((participants) => {
          $uibModal.open({
            controllerAs: 'modalController',
            controller: function ($uibModalInstance) {
              const modalController = this;

              modalController.modal = $uibModalInstance;
              modalController.evaluation = participantListController.evaluation;
              modalController.participants = _(participants).filter({ manual: true }).orderBy('fullName').value();

              modalController.onSave = function() {
                loadData();
              };
            },
            template: `
              <manage-participants-modal
                participants="modalController.participants"
                evaluation-id="{{ modalController.evaluation.id }}"
                on-save="modalController.onSave()"
                modal="modalController.modal">
              </manage-participants-modal>
            `
          });
        }).finally(() => {
          participantListController.loading = false;
        });
      };

      participantListController.syncPersons = function () {
        message.addInfoLabel('Message.SyncPersons.Started');
        Participant.syncPersons({
          evaluation: participantListController.evaluation.id
        }, {}).$promise.then(() => {
          loadData();
          message.addSuccessLabel('Message.SyncPersons.Finished');
        }).catch(() => {
          message.addErrorLabel('Message.SyncPersons.Error');
        });
      };

      participantListController.deleteAll = function () {
        participantListController.loading = true;
        Participant.deleteAll({
          evaluationId: participantListController.evaluation.id
        }).$promise.then(loadData).finally(() => {
          participantListController.loading = false;
        });
      };
    }
  });
