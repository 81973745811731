'use strict';

/**
 * @ngdoc component
 * @name diceApp.component:notificationTemplateSelect
 * @description Shows a dropdown select for notification templates of a specific evaluation type.
 */
angular.module('diceApp')
  .component('notificationTemplateSelect', {
    templateUrl: 'es6/notifications/template/notifications.template.select.html',
    require: {
      ngModel: 'ngModel'
    },
    controllerAs: 'notificationTemplateSelectController',
    controller: function (NotificationTemplate, $scope) {
      const notificationTemplateSelectController = this;

      notificationTemplateSelectController.$onInit = function () {
        loadData();
        $scope.$watch('notificationTemplateSelectController.evaluationType', loadData);
        $scope.$watch('notificationTemplateSelectController.ngModel.$modelValue', function () {
          notificationTemplateSelectController.value = notificationTemplateSelectController.ngModel.$modelValue;
        });
      };

      notificationTemplateSelectController.onChange = function () {
        notificationTemplateSelectController.ngModel.$setViewValue(notificationTemplateSelectController.value);
      };

      function loadData() {
        NotificationTemplate.getByEvaluationType(notificationTemplateSelectController.evaluationType)
          .then((notificationTemplates) => {
            notificationTemplateSelectController.templates = notificationTemplates;
          });
      }
    }
  });
